<template>
  <div class="tracking" v-if="transfer">
    <b-row class="tracking_head">
      <b-col cols="12" lg="6" class="tracking_head_logo">
        <img src="@/assets/images/tracking/pl_tracking_logo.svg" alt="">
      </b-col>
      <b-col v-if="postcode" cols="12" lg="6" class="tracking_head_right">
        <b-btn v-if="!contactInfoVisible" @click="contactInfoVisible = true">{{
            isPickup ? $t('tracking.change_pickup') : $t('tracking.change_delivery')
          }}
        </b-btn>
        <div class="tracking_head_right_contact" v-else>
          <div>
            <span>{{ $t('tracking.contact.headline') }}</span>
            <p class="bold">{{ $t('tracking.contact.text') }}</p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="tracking_body">
      <b-col cols="12" lg="4" class="tracking_body_info">
        <b-col class="tracking_body_info_main">
          <h3>{{ $t('tracking.info.headline', {client_name: transfer.client.name}) }}</h3>
          <p class="tracking_body_info_main_row"/>

          <div v-if="transfer.start_shop && transfer.end_shop">
            <div class="tracking_body_info_main_row--left">
              {{ $t('tracking.info.pickup') }}:
            </div>
            <div class="tracking_body_info_main_row--right">
              <p>{{ transfer.start_shop.address.street_name_and_number }}</p>
              <p>{{ transfer.start_shop.address.postcode }}, {{ transfer.start_shop.address.city_name }}</p>
            </div>

            <div class="tracking_body_info_main_row--left">
              {{ $t('tracking.info.delivery') }}:
            </div>
            <div class="tracking_body_info_main_row--right">
              <p>{{ transfer.end_shop.address.street_name_and_number }}</p>
              <p>{{ transfer.end_shop.address.postcode }}, {{ transfer.end_shop.address.city_name }}</p>
            </div>
          </div>

          <div class="tracking_body_info_main_row--left">
            {{ isPickup ? $t('tracking.info.pickup_date') : $t('tracking.info.delivery_date') }}
          </div>
          <div class="tracking_body_info_main_row--right green">
            <span class="bold">{{ dayString }}</span>
            <p>{{ timeString }}</p>
          </div>

          <h4>{{ $t('tracking.info.car_info') }}</h4>
          <div class="tracking_body_info_main_row--left">{{ $t('tracking.info.tracking_id') }}</div>
          <div class="tracking_body_info_main_row--right">{{ trackingId }}</div>

          <div class="tracking_body_info_main_row--left">{{ $t('tracking.info.vin') }}</div>
          <div class="tracking_body_info_main_row--right">{{ transfer.vin }}</div>

          <div class="tracking_body_info_main_row--left">{{ $t('tracking.info.model') }}</div>
          <div class="tracking_body_info_main_row--right">{{ vehicleModel }}</div>

        </b-col>
        <div v-if="token && transfer.requires_input" class="tracking_body_info_disclaimer red">
          {{ $t('tracking.info.disclaimer') }}
        </div>
      </b-col>
      <template v-if="postcode === null">
        <b-col lg="8" sm="12">
          <div class="tracking_body_postcode">
            <b-row>
              <b-col cols="12" class="tracking_body_headline">{{ $t('tracking.postcode.headline') }}</b-col>
              <b-col cols="12" xl="5" lg="8">
                <b-form @submit.prevent="submitPostcode">
                  <generic-input
                      :label="$t('tracking.postcode.label')"
                      v-model="temporaryPostcode"
                      required/>
                  <p v-if="apiError" class="red">{{ errorMessage }}</p>
                  <b-button class="float-right button-secondary" type="submit">
                    {{ $t('tracking.submit') }}
                  </b-button>
                </b-form>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </template>
      <template v-else-if="token && transfer.requires_input">
        <b-col lg="8" sm="12">
          <b-form @submit.prevent="submitMetadata">
            <b-row>
              <b-col sm="12" class="tracking_body_headline">{{ $t('tracking.data.headline') }}</b-col>
              <b-col cols="12" lg="6">
                <generic-input :label="$t('tracking.data.numberplate')"
                               required
                               v-model="transfer.license_plate"/>
              </b-col>
              <b-col cols="12" lg="6" v-for="meta in transfer.metadata">
                <generic-input :label="meta.label || meta.name"
                               v-model="meta.value"/>
              </b-col>
              <b-col cols="12">
                <p v-if="apiError" class="red">{{ errorMessage }}</p>
                <b-button class="float-right button-secondary" type="submit">
                  {{ $t('tracking.submit') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </template>
      <template v-else-if="transfer.state >= 0">
        <b-col lg="8" sm="12">
          <b-row>
            <b-col sm="12" class="tracking_body_headline">{{ $t('tracking.tracking.headline') }}</b-col>
            <b-col sm="12">
              <progress-bar :state="getStateForStep(1)" :headline="$t('tracking.tracking.state1')"/>
              <progress-bar :state="getStateForStep(2)" :headline="$t('tracking.tracking.state2')"/>
              <progress-bar :state="getStateForStep(3)" :headline="$t('tracking.tracking.state3')"/>
              <progress-bar :state="getStateForStep(4)" :last="true" :headline="$t('tracking.tracking.state4')"/>
            </b-col>
          </b-row>
        </b-col>
      </template>
      <template v-else>
        <h2 class="red">{{ $t('tracking.tracking.error') }}</h2>
      </template>
    </b-row>

    <!-- footer -->
    <div class="footer">
      <b-row>

        <b-col md order="1" order-md="0">
          <img alt="prologistics logo" style="width: 80px" src="@/assets/images/prologistics_logo.png"> | {{ $t("prologistics.slogan") }} &copy; {{ year }}
        </b-col>

        <b-col md order="0" order-md="1">
          <shared-links/>
        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>

import GenericInput from "@/components/generic_input";
import ProgressBar from "@/components/tracking/progress_bar";
import {getTransfer, getTransferDetail, putTransfer} from "@/services/tracking";
import {EventBus} from "@/eventbus";
import SharedLinks from "@/components/shared_links";

export default {
  components: {SharedLinks, ProgressBar, GenericInput},
  data() {
    return {
      contactInfoVisible: false,
      errorMessage: null,
      apiError: null,
      temporaryPostcode: null,
      postcode: null,
      transfer: null,
      trackingId: null,
      token: null
    }
  },
  mounted() {
    document.title = "Fahrzeuglieferung - Live Tracking"
    EventBus.$off("toast") // disabling all request result toasts for now
    this.trackingId = this.$route.params.id
    this.token = this.$route.query.token
    getTransfer(this.trackingId).then((response) => {
      this.transfer = response
    }).catch((error) => {
      this.$router.push({name: '404'})
    })
  },
  computed: {
    year() {
      return new Date().getFullYear()
    },
    isPickup() {
      return this.$route.query.token
    },
    dayString() {
      const date_range = this.isPickup ? this.transfer.date_range : this.transfer.delivery_date_range
      return this.$options.filters.formatDateRangeDay(date_range)
    },
    timeString() {
      const date_range = this.isPickup ? this.transfer.date_range : this.transfer.delivery_date_range
      const time = this.$options.filters.formatDateRangeTime(date_range)
      return (date_range.length > 1 ? this.$t('tracking.info.between') : this.$t('tracking.info.at')) + " " + time + " " + this.$t("tracking.info.time")
    },
    vehicleModel() {
      return this.transfer.vehicle.brand + ' ' + this.transfer.vehicle.model
    }
  },
  methods: {
    getDefaultMetadata() {
      return [
        {name: this.$t('tracking.data.input1'), value: ""},
        {name: this.$t('tracking.data.input2'), value: ""},
        {label: this.$t('tracking.data.input3_label'), name: this.$t('tracking.data.input3'), value: ""}
      ]
    },
    getStateForStep(step) {
      if (this.transfer.state === (step - 1)) {
        return step === 4 ? 100 : 50
      }
      if (this.transfer.state < (step - 1)) {
        return 0
      }
      return 100
    },
    submitPostcode() {
      // TODO: block ui
      getTransferDetail(this.trackingId, this.temporaryPostcode).then((response) => {
        this.transfer = response
        this.transfer.metadata = this.getDefaultMetadata()
        this.postcode = this.temporaryPostcode
        this.apiError = false
      }).catch(error => {
        this.errorMessage = error.response?.data?.error || error.message
        this.apiError = true
      })
    },
    submitMetadata() {
      // TODO: block ui
      putTransfer(this.trackingId, this.postcode, this.token, {
        license_plate: this.transfer.license_plate,
        metadata: this.transfer.metadata.filter(meta => meta.value.trim().length > 0)
      }).then((response) => {
        this.transfer.requires_input = false
      }).catch((error) => {
        this.errorMessage = error.response?.data?.error || error.message
        this.apiError = true
        // TODO show error
      })
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/custom/variables";

$spacing-small: 10px;
$spacing-medium: 50px;
$spacing-large: 100px;
$color-primary: #8000FF;

.tracking {
  font-family: 'Poppins', Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;

  min-height: 100vh;

  .footer {
    margin-left: 0;

    img {
      filter: grayscale(1);
    }
  }

  label {
    position: relative;
    font-size: 10px;
    padding: 0 10px;
    left: 7px;
    background-color: white;
    width: min-content;
    color: #656565;
    font-weight: bold;
  }

  input {
    margin-top: -18px;
    border-radius: 11px;
    padding: 20px 10px;


    &:focus {
      border: 2px solid #8000FF;
      //  revert border grow
      padding: 19px 9px;
    }
  }

  .green {
    color: #36EB00;
  }

  .red {
    color: #dd2525;
  }

  .bold {
    font-weight: bold;
  }

  button {
    font-weight: bold;
    padding-left: $spacing-medium;
    padding-right: $spacing-medium;
    background-color: #fff;
    color: $color-primary;
    border-color: $color-primary;
    cursor: pointer;

    &:active {
      background-color: $color-primary;
      color: #fff;
      border-color: $color-primary;
    }

    &:hover {
      background-color: $color-primary;
      color: #fff;
      border-color: $color-primary;
    }

    &.button-secondary {
      background-color: $color-primary;
      color: #fff;
      border-color: $color-primary;

      &.disabled {
        cursor: not-allowed;
        background-color: #dadada;
        color: #fff;
        border-color: #dadada;


        &:hover {
          background-color: #a9a9a9;
          color: #fff;
          border-color: #a9a9a9;
        }
      }

      &:active {
        background-color: #fff;
        color: $color-primary;
        border-color: $color-primary;
      }

      &:hover {
        background-color: #fff;
        color: $color-primary;
        border-color: $color-primary;
      }
    }
  }


  background-color: #fff;
  color: #000;
  padding-left: $spacing-large;
  padding-right: $spacing-large;
  padding-top: $spacing-medium;


  @media only screen and (max-width: 700px) {
    padding: $spacing-small*2;
    padding-bottom: calc(#{$footer-height-md} + #{$spacing-small});
  }

  &_head {
    padding-bottom: $spacing-medium;

    &_logo {
      height: 100%;
      width: auto;
      margin-bottom: $spacing-medium;
    }

    &_right {
      text-align: right;

      &_contact {
        img {
          vertical-align: baseline;
          margin-right: $spacing-small;
        }

        div {
          text-align: left;
          vertical-align: baseline;
          display: inline-block;
        }
      }
    }
  }

  &_body {
    &_info {
      margin-bottom: $spacing-medium;

      &_main {
        background-color: #fafafa;
        padding: $spacing-small $spacing-small $spacing-medium;

        &_row {
          &--left {
            vertical-align: top;
            display: inline-block;
            font-weight: bold;
            width: 40%;
            @media only screen and (max-width: 700px) {
              width: 100%;
            }

          }

          &--right {
            font-weight: 400;
            vertical-align: top;
            display: inline-block;
            width: 60%;
            @media only screen and (max-width: 700px) {
              width: 100%;
            }
          }
        }
      }

      &_disclaimer {
        margin-left: $spacing-small;
        margin-top: $spacing-small;
        font-weight: bold;
        font-size: 10px;
      }
    }

    &_headline {
      font-weight: bold;
      font-size: 13px;
      margin-top: $spacing-small;
      margin-bottom: 20px;
    }
  }
}

</style>
