<template>
  <div class="progressbar">
    <div class="progressbar_headline">{{ headline }}</div>

    <div class="progressbar_inner">
      <div class="progressbar_inner_fill" :class="'progressbar_inner_fill--' + state"/>
      <div class="progressbar_inner_icon" :class="'progressbar_inner_icon--' + (last ? 'last' : state)">
        <div v-if="last" class="progressbar_inner_icon--bg"></div>
        <img :src="iconPath" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    state: {
      type: Number,
      default: 0
    },
    headline: {
      type: String,
      default: null
    },
    last: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconPath() {
      if (this.last) {
        if (this.state === 100) {
          return require("@/assets/images/tracking/pl_tracking_last_done.svg")
        }
        return require("@/assets/images/tracking/pl_tracking_last.svg")
      }

      if (this.state === 100) {
        return require("@/assets/images/tracking/pl_tracking_done.svg")
      }

      if (this.state === 0) {
        return ''
      }
      return require("@/assets/images/tracking/pl_tracking_progress.svg")
    }
  }
};
</script>

<style scoped lang="scss">
.progressbar {
  width: 50%;
  padding: 20px;
  display: inline-block;
  position: relative;

  @media only screen and (max-width: 700px) {
    width: 100%;
  }

  &_headline {
    white-space: nowrap;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &_inner {
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;
    height: 10px;
    background-color: #EFEFEF;
    border-radius: 10px;

    &_icon--bg {
      position: absolute;
      width: 50px;
      height: 50px;
      background: #fff;
      right: 15px;
      bottom: 25px;
      transform: rotate(45deg);
    }

    &_icon > img {
      max-height: 37px;
      left: calc(50% - 22.5px);
      position: absolute;
      top: 45px
    }

    &_icon--100 > img {
      left: 15px;
    }

    &_icon--last > img {
      width: 50px;
      left: calc(100% - 65px);
      background-color: #fff;
      top: 35px;
    }

    &_fill {
      border-radius: 10px;
      height: 100%;
      background-color: #36EB00;
      z-index: 2;

      &--0 {
        width: 0;
      }

      &--50 {
        width: 50%;
      }

      &--100 {
        width: 100%;
      }
    }
  }
}
</style>
